$(document).on('turbolinks:load', function() {
  $('.navbar-toggler').on('click', function() {
    if ($(this).hasClass('active-toggler')) {
      $(this).removeClass('active-toggler')
      $('#navbarSupportedContent').removeClass('show')
      $('.navbar').removeClass('navbar--open')
      $('main, footer').show()
    } else {
      $(this).addClass('active-toggler')
      $('#navbarSupportedContent').addClass('show')
      $('.navbar').addClass('navbar--open')
      $('main, footer').hide()
    }
  })
  
  $(window).on('resize', function() {
    var windowsize = $(window).width();
    if (windowsize >= 1200) { 
      $('main, footer').show()
    } else {
      if ($('#navbarSupportedContent').hasClass('show')) {
        $('main, footer').hide()
      }
    }
  })

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 30) {
      $('.navbar').addClass('navbar--active')
    } else {
      $('.navbar').removeClass('navbar--active')
    }
  })  
  
  $('.faq-items input[type=checkbox]').on("change", function(e) { 
    if ($("#" + e.target.id).prop("checked") === true) {
      $('.faq-items input[type=checkbox]').prop("checked", false)
      $("#" + e.target.id).prop("checked", true)
    }
  });

  if (('#question_company_select').length) $('#question_company_type').val($('#question_company_select').val())
    
  $('#question_company_select').on("change", function() {
    var parent = $('#question_company_type').parents('.form-group')
    if ($(this).val() === "Others") {
      parent.removeClass('d-none')
      $('#question_company_type').val('')
    } else {
      parent.addClass('d-none')
      $('#question_company_type').val($(this).val())
    }
  })

  if (('#join_company_select').length) $('#join_company_type').val($('#join_company_select').val())
    
  $('#join_company_select').on("change", function() {
    var parent = $('#join_company_type').parents('.form-group')
    if ($(this).val() === "Others") {
      parent.removeClass('d-none')
      $('#join_company_type').val('')
    } else {
      parent.addClass('d-none')
      $('#join_company_type').val($(this).val())
    }
  })

  $('#join_membership').on("change", function() {
    $(this).val() === "active_member" 
      ? $("#active_membership").removeClass('d-none')
      : $("#active_membership").addClass('d-none')
  })
})